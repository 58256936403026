<template>

	<a-card :bordered="false" class="dashboard-bar-line header-solid">
		<template #title>
			<h6>Balance History</h6>				
		</template>
		<template #extra>
			<a-badge color="primary" class="badge-dot-primary" text="Balance" />
		</template>
		<chart-line :height="310" :data="lineChartData"></chart-line>
	</a-card>

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartLine from '../Charts/ChartLine' ;

	export default ({
		components: {
			ChartLine,
		},
		data() {
			return {

				// Data for line chart.
				lineChartData: {
					labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep"],
					datasets: [{
						label: "Remaining Balance",
						tension: 0.4,
						borderWidth: 0,
						pointRadius: 0,
						borderColor: "#1890FF",
						borderWidth: 3,
						data: [19026, 18910, 18826, 18726, 18526, 18410],
						maxBarThickness: 6

					}],
				},
			}
		},
	})

</script>